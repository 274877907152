<template>
  <div>
    <div class="relative">
      <hero
        :hero-image="store.hero_image"
        justify-content="center"
        size="small"
      >
        <div class="p-2 md:p-5 flex lg:rounded-md lg:max-w-1/2 bg-white">
          <div class="flex mr-6 h-20">
            <img :src="store.logo" :alt="store.name" />
          </div>

          <div>
            <h1 class="text-lg font-semibold">{{ store.name }}</h1>
            <div>{{ store.description }}</div>
            <div class="text-">
              <i class="fas fa-compass"></i>
              {{ store.address }}
            </div>

            <div>
              <i class="fas fa-clock"></i>
              {{ store.open_time }}-{{ store.close_time }}
            </div>
          </div>
        </div>
      </hero>
    </div>

    <div class="mx-auto py-4 sm:py-10 lg:max-w-7xl">
      <div class="text-center p-10 font-bold text-2xl">
        <router-link
          class="border-2 text-black active:bg-blueGray-600 font-bold uppercase px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          active-class="active"
          to="catalog"
          append
        >
          <i class="fas fa-utensils"></i>
          {{ $t('menu') }}
        </router-link>
      </div>

      <h2 v-if="positions.length > 0" class="text-2xl font-semibold p-2">
        {{ $t('where-do-you-seat') }}
      </h2>
      <div
        class="p-2 h-auto grid-cols-3 md:grid-cols-4 lg:grid-cols-10 gap-1 grid"
      >
        <position
          class="text-center text-lg flex-1"
          v-for="item in positions"
          :key="item.id"
          :name="item.name"
          :storeId="store.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { FETCH_STORE_REQUEST } from '@/store/actions/init'
import Position from '@/components/Position.vue'
import Hero from '@/components/Hero.vue'

export default {
  name: 'Store',

  components: {
    Position,
    Hero,
  },

  created() {
    const storeId = this.$route.params.id
    this.$store.dispatch(FETCH_STORE_REQUEST, storeId)
  },

  computed: {
    store() {
      return this.$store.getters.getStore
    },
    positions() {
      return this.$store.getters.getPositions
    },
  },
}
</script>

import axios from 'axios'
import { settings } from '@/settings'

const API_SERVER = settings.API_SERVER
const API_DOMAIN = settings.API_DOMAIN

export const api_routes = {
  cms: API_DOMAIN + '/c',
  root: API_SERVER,
  store: API_SERVER + '/stores',
  cart: API_SERVER + '/carts',
  order: API_SERVER + '/orders',
  card: API_SERVER + '/cards',
  localizations: API_SERVER + '/localizations',
}

export const apiCall = ({ url, method, ...args }) =>
  new Promise((resolve, reject) => {
    ensureAuthenticated()

    axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        return Promise.reject(error)
      }
    )

    try {
      axios({
        method: method || 'get',
        url: url,
        withCredentials: true,
        ...args,
      })
        .then(resp => {
          saveAuthentication(resp)
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    } catch (err) {
      reject(new Error(err))
    }
  })

const ensureAuthenticated = () => {
  let token = localStorage.getItem('auth.token') || null

  if (!!token) {
    axios.defaults.headers.common['Authorization'] = token
  }
}

const saveAuthentication = resp => {
  if (resp.status === 200) {
    const authHeader = resp.config.headers['Authorization']
    if (!!authHeader) {
      localStorage.setItem('auth.token', authHeader)
    }
  }
}

function isOnlineOrderingAllowed(availableFeatures) {
  return isAllowed('online_order', availableFeatures)
}

function isCardPaymentAllowed(availableFeatures) {
  return isAllowed('card_payment', availableFeatures)
}

function isCashPaymentAllowed(availableFeatures) {
  return isAllowed('cash_payment', availableFeatures)
}

function isLoyaltyAllowed(availableFeatures) {
  return isAllowed('loyalty', availableFeatures)
}

function isDeliveryAllowed(availableFeatures) {
  return isAllowed('delivery', availableFeatures)
}

function isPickupAllowed(availableFeatures) {
  return isAllowed('pickup', availableFeatures)
}

function isAllowed(feature, availableFeatures) {
  if (feature === undefined || availableFeatures === undefined) {
    return false
  }
  if (availableFeatures.length === 0) {
    return false
  }
  return availableFeatures.includes(feature)
}

export const FeatureService = {
  isOnlineOrderingAllowed,
  isCardPaymentAllowed,
  isCashPaymentAllowed,
  isLoyaltyAllowed,
  isDeliveryAllowed,
  isPickupAllowed,
}

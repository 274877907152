import Vue from 'vue'
import Vuex from 'vuex'
import settings from './modules/settings'
import init from './modules/init'
import { isProd } from '../settings'
import catalog from './modules/catalog'
import cart from './modules/cart'
import order from './modules/order'
import cms from './modules/cms'
import card from './modules/card'
import localizations from './modules/localizations'

Vue.use(Vuex)

const debug = !isProd()

export default new Vuex.Store({
  modules: {
    init,
    settings,
    catalog,
    cart,
    order,
    cms,
    card,
    localizations,
  },
  strict: debug,
})
